import styled from "styled-components"

const Separator = styled.div`
  width: 50px;
  height: 5px;
  background-color: #fff;
  margin: 40px 0;
`

export default Separator
