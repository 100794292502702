import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Cell } from "styled-css-grid"
import styled from "styled-components"

import Separator from "./separator"
import Container from "./container"

const FooterContainer = styled.footer`
  background: #000;
  color: #fff;
  padding: 70px;

  h2 {
    color: #fff;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fb43ff;
    }
  }
`

const EmailLink = styled.a`
  font-size: 2.5rem;
  font-weight: 200;
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  return (
    <FooterContainer>
      <Container>
        <h2>Have a project? Let’s talk.</h2>
        <EmailLink href="mailto:hello@codespire.co">
          hello@codespire.co
        </EmailLink>

        <Separator />

        <Grid columns="repeat(auto-fit,minmax(120px,1fr))">
          <Cell width={2}>
            <p>
              71-75 Shelton Street <br />
              Covent Garden <br />
              WC2H 9JQ <br />
              London
            </p>

            <small>Company number: 10305179</small>
          </Cell>
          <Cell width={1}>
            <p>
              <a href="https://twitter.com/codespire">Twitter</a>
              <br />
              <a href="https://facebook.com/codespire">Facebook</a>
              <br />
              <a href="https://www.linkedin.com/company/codespire">LinkedIn</a>
              <br />
              <br />
            </p>

            <small>&copy; 2019 Codespire Limited. All rights reserved.</small>
          </Cell>
        </Grid>
      </Container>
    </FooterContainer>
  )
}

export default Footer
